<template>
    <div id="poc" class="p-t-4 p-b-4 section-bg-dark">
    <b-container>

    <!--Section heading-->
    <div class="text-center">
        <h1 class="font-weight-bold text-center my-4">Contact us</h1>
        <p>
            Interested in using our automated biomarkers in your research? Please don't hesitate to contact us directly!<br/>
        </p>   
    </div>

    <div class="row align-items-center">
<!--Section description-->

            <div class="col-md-12 text-center">
                <ul class="list-unstyled mb-0">
                    <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                        <p>kevin.a.thomas@stanford.edu</p>
                    </li>
                </ul>
            </div>
            <!--Grid column-->

    </div>
    </b-container>
    </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data () {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  computed: {
    contactDisabled: function () {
      return !(this.name && this.email && this.message)
    }
  }
}
</script>
