<template>
  <b-container fluid class="py-2 mb-2 mt-2 text-center" style="background: #fafafa; border-top: 1px solid #e0e0e0; border-bottom: 1px solid #e0e0e0;">
    <strong>Neural network status: </strong><span v-html="networkStatus"> </span>
  </b-container>
</template>

<script>
export default {
  name: 'Status',
  props: {
    networkStatus: String,
  }
}
</script>