export default (long) => {
  // we want to represent the input as a 8-bytes array
  var byteArray = [0, 0, 0, 0]

  for (let index = 0; index < byteArray.length; index ++) {
    var byte = long & 0xff
    byteArray [ index ] = byte
    long = (long - byte) / 256
  }

  return byteArray
}