<template>
  <div class="container mb-5">
    <h1 class="h1-responsive font-weight-bold text-center my-4" style="padding: 0.5em;">Automated segmentation results for your images</h1>

    <div class="ml-md-5 mr-md-5 text-container">      
      <div class="mr-md-3 text-col">
        <b-table
          :items="images"
          :fields="fields"
          hover
          @row-clicked="rowClicked">

          <template slot="filename" slot-scope="data">          
            <span :class="[(data.index === value) ? 'text-selected' : '']">{{ data.item.filename }}</span>
          </template>

          <template slot="download" slot-scope="data">
            <b-button
              size="sm"
              variant="brown"
              class="download-button"
              :disabled="data.item.exporting"
              @click="$emit('download', data.index)">
              <i
                v-if="data.item.exporting"
                class="fas fa-spinner fa-spin mr-2"/>
              Download masks
            </b-button>
          </template>
        </b-table>
      </div>
      
      <div class="image-col">
        <div
          class="image-stack"
          :style="imageStackStyles">
          <img
            class="back"
            :src="images[value].dicoms[dicomIndex].src"
            :width="width"
            :height="height">

          <img
            class="mask"
            :src="images[value].dicoms[dicomIndex].maskSrc"
            :width="width"
            :height="height">
        </div>

        <div class="button d-flex justify-content-center mt-1">
          <b-button
            class="mr-1"
            size="sm" variant="brown"
            :disabled="dicomIndex <= 0"            
            @click="dicomIndex--">Prev</b-button>
          <b-button
            size="sm" variant="brown"
            :disabled="dicomIndex >= (images[value].dicoms.length - 1)"
            @click="dicomIndex++">Next</b-button>
        </div>
      </div>
    </div> 
  </div>  
</template>

<script>
export default {
  name: 'ResultsMRI',
  props: {
    value: Number, // selected index
    images: Array,
    width: Number,
    height: Number
  },
  data () {
    return {
      fields: [
        { key: 'filename', label: 'Filename', sortable: false },
        //{ key: 'score', label: 'KL Score', sortable: false },
        //{ key: 'confidence', label: 'Confidence', sortable: false },
        { key: 'download', label: '', sortable: false, tdClass: "text-right" }
      ]
    }
  },
  computed: {
    dicomIndex: {
      get () {
        return this.images[this.value].dicomIndex
      },
      set (value) {
        this.images[this.value].dicomIndex = value
      }
    },
    imageStackStyles () {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`
      }
    }
  },
  methods: {
    rowClicked (item, index, event) {
      this.$emit('input', index)
    }
  }
}
</script>

<style lang="scss">
.text-selected {
  font-weight: bold;
}

.modal {
    position: fixed;
    top: 40% !important;
}

.download-button {
  width: 160px;
}

.image-col {
  .image-stack {
    position: relative;

    .back, .mask {
      position: absolute;
      left: 0;
      top: 0;
    }

    .mask {
      z-index: 2;
    }
  }
}
</style>