<template>
  <div class="container mb-5">
    <h1 class="h1-responsive font-weight-bold text-center my-4" style="padding: 0.5em;">Evaluation using artificial intelligence</h1>
    <div class="ml-md-5 mr-md-5 text-container">      
      <div class="mr-md-3 text-col">
        <b-table :items="images" :fields="fields" hover @row-clicked="rowClicked">
          <template slot="filename" slot-scope="data">          
            <span :class="[(data.index === value) ? 'text-selected' : '']">{{ data.item.filename }}</span>
          </template>

          <template slot="score" slot-scope="data">
            <span :class="[(data.index === value) ? 'text-selected' : '']">
              {{ data.item.res[0].className }} 
            </span>
          </template>
          <template slot="confidence" slot-scope="data">
            <span :class="[(data.index === value) ? 'text-selected' : '']">
              {{ (data.item.res[0].probability*100) | round(2) }}%
            </span>
          </template>
        </b-table>
      </div>
      
  <!-- Modal Component -->
  <b-modal id="modal1" title="BootstrapVue" ref="KLmodal" hide-footer>
    <p class="my-4">Hello from modal!</p>
  </b-modal>

      <div class="image-col">
        <img id="selectedImage" :src="images[value].img.src" :width="width" :height="height" alt="Knee cap">

        <div class="mt-2 text-center">
          <strong style="font-size: 2em">{{ images[value].res[0].className }}</strong><br/>
          <span style="color:#777777;">(confidence = {{ (images[value].res[0].probability*100) | round(2) }}%)</span>
        </div>
      </div>
    </div> 
  </div>  
</template>

<script>
export default {
  name: 'Results',
  props: {
    value: Number, // selected index
    images: Array,
    width: Number,
    height: Number
  },
  data () {
    return {
      fields: [
        { key: 'filename', label: 'Filename', sortable: false },
        { key: 'score', label: 'KL Score', sortable: false },
        { key: 'confidence', label: 'Confidence', sortable: false },
      ]
    }
  },
  methods: {
    rowClicked (item, index, event) {
      this.$emit('input', index)
    }
  }
}
</script>

<style>
.text-selected {
  font-weight: bold;
}
.modal {
    position: fixed;
    top: 40% !important;
}
</style>