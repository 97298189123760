<template>
  <div class="main">  
    <Toast v-for="t in $store.state.toasts.toasts"
           :key="t.id"
           :data="t">
    </Toast>
  </div>
</template>

<script>
import Toast from './/Toast'

export default {
  name: 'Toasts',
  components: {
    Toast
  }
}
</script>

<style>
div.main {
  position: absolute; 
  bottom: 5px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: 10000; 
  width: 400px;   
  opacity: 1;
}
</style>

