<template>
  <div id="app">
    <NavBar/>
    <router-view/>
    <Footer/>
    <ToastPanel/>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ToastPanel from '@/components/Toasts/ToastPanel'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    ToastPanel
  },
  created () {
    // extract API key for future use
    // if (this.$route.query.key) {
    //   this.$store.commit('setAPIKey', this.$route.query.key)
    // }
  }
}
</script>
