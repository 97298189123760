// Find otsu threshold (adapt to the histogram)
// Read https://en.wikipedia.org/wiki/Otsu%27s_method (added this since JS examples in wikipedia didn't work)
function otsu (histData /* Array of 256 greyscale values */, total /* Total number of pixels */) {
  let sum = 0;
  for (let t=0 ; t<256 ; t++) sum += t * histData[t];
  let sumB = 0;
  let wB = 0;
  let wF = 0;
  let varMax = 0;
  let threshold = 0;
  for (let t=0 ; t<256 ; t++) {
      wB += histData[t];               // Weight Background
      if (wB == 0) continue;
      wF = total - wB;                 // Weight Foreground
      if (wF == 0) break;
      sumB += t * histData[t];
      let mB = sumB / wB;            // Mean Background
      let mF = (sum - sumB) / wF;    // Mean Foreground
      // Calculate Between Class Variance
      let varBetween = wB * wF * (mB - mF) * (mB - mF);
      // Check if new maximum found
      if (varBetween > varMax) {
          varMax = varBetween;
          threshold = t;
      }
  }
  return threshold;
}

// get id in the array of the point (i,j)
function getPoint(data,i,j){
  return j*(data.width*4) + i*4;
}

// get histogram of colors in the image
// used for otsu thresholding
function histogram(data){
  var res = []
  for(var i = 0; i < 256; i++) 
    res.push(0);
    
  for (var i = 0; i < data.width; i++){
    for (var j = 0; j < data.height; j++){
      var pt = getPoint(data,i,j);
      res[data.data[pt]] += 1
    }
  }
  
  return res;
}

// threshold ImageData over the first channel with a threshold 'thresh'
function threshold(data, thresh){
  for (var i = 0; i < data.width; i++){
    for (var j = 0; j < data.height; j++){
      var pt = getPoint(data,i,j);
      if (data.data[pt] > thresh){
        data.data[pt] = 1;
        data.data[pt+1] = 1;
        data.data[pt+2] = 1;
      }
      else {
        data.data[pt] = 0;
        data.data[pt+1] = 0;
        data.data[pt+2] = 0;
      }
    }
  }
  return data;
}

// where m is the sum of 1s
function centerOfMass(data){
  var sx = 0; // sum of x positions of masses
  var sy = 0; // sum of y positions of masses
  var m = 0;  // total mass
  
  for (var i = 0; i < data.width; i++){
    for (var j = 0; j < data.height; j++){
        var pt = getPoint(data,i,j);
	if (data.data[pt] > 0){
	  m += 1;
	  sx += i;
	  sy += j;
	}
    }
  }
  // return to points as integers
  return [Math.round(sx/m),Math.round(sy/m)]
}

// get means of columns and threshold at 0.2
function sumColumns(data){
  var res = []
  for (var i = 0; i < data.width; i++){
    var m = 0;
    for (var j = 0; j < data.height; j++){
      var pt = getPoint(data,i,j);
      if (data.data[pt] > 0)
        m += 1;
    }
    res.push( (m / data.height) > .2)
  }
  return res;
}

// get canvas element and count the number of knees
function countKneesInCanvas(canvas){
  var context = canvas.getContext('2d');
  // get an image and apply otsu thresholding
  var myData = context.getImageData(0, 0, canvas.width, canvas.height);
  var thresh = otsu(histogram(myData), myData.width * myData.height);
  myData = threshold(myData, thresh);
  // get thresholded mass in columns
  var colmass = sumColumns(myData);
  // find mass in columns of interest
  var ncol = colmass.length;
  var cmass = centerOfMass(myData);
  var centersum = 0;
  var window = 0.15;
  var lower = Math.round(cmass[0]-ncol*window);
  var upper = Math.round(cmass[0]+ncol*window);
  var colMassSlice = colmass.slice(lower, upper+1);
  // if variance zero than solid mass in the center => 1 knee
  if (Math.min.apply(null, colMassSlice) == Math.max.apply(null, colMassSlice)){
    console.log("Found 1 knee")
    return 1;
  }
  // otherwise two knees
  console.log("Found 2 knees")
  return 2;
}

export {
  countKneesInCanvas
}