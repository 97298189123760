<template>
  <b-container fluid class="header-image text-light">
    <b-row align-h="center">
      <b-col>
        <h1 class="h1-responsive font-weight-bold text-center pb-2 contact-us">Analyze knee osteoarthritis images automatically with the latest computer vision technology</h1>
      </b-col>
    </b-row>

    <div class="container">
      <div class="row">
      <div class="col-md-8" style="font-size: 1.3em;">
      Drag and drop images into your browser to have them analyzed securely within seconds using machine learning models developed by the Stanford University Neuromuscular Biomechanics Lab. No data ever leaves your computer.
        <p><br /> Our current biomarkers include: 
          <ul>
          <li>Automated assessment of knee osteoarthritis severity from X-rays</li>
          <li>Automated segmentation of femoral cartilage in T2 map MRIs</li>
          </ul>
      </p>
      Use the navigation bar above to select a tool to try        
      </div>

      <div class="col-md-4">
        <img src="@/assets/img/saliency.png" width="300" height="300" alt="Knee cap"><br />        
      </div>
      </div>
    </div>
    </div>
  </b-container>    
</template>

<script>
export default {
  name: 'TextInfoBlock'
}
</script>

