<template>
  <b-navbar toggleable="lg" class="bg-light">
    <div class="container"> <!-- from original html -->
      <b-navbar-brand to="/">
        <!-- <img src="@/assets/img/logo.png" id="skyblu-logo" class="d-inline-block align-top" alt="saliency.ai logo"> -->
        <img src="@/assets/img/kneelogo.png" id="skyblu-logo" class="d-inline-block align-top" alt="kneenet logo" height="50px" width="50px">
        KneeNet
      </b-navbar-brand>

        <b-collapse is-nav id="nav_collapse" class="py-3">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-for="(mi, idx) in menuItems"
                        :key="idx"
                        :to="mi.to ? mi.to : null"
                        @click="onClick(mi)"
                        exact
                        active-class="active"
                        :active="mi.to === $route.path"
                        class="pl-0">
              {{ mi.label }}
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>

        <b-navbar-toggle target="nav_collapse" class="mt-0"></b-navbar-toggle>        
      </span>
    </div>
  </b-navbar>
</template>

<script>
import store from '@/store.js'
import router from '@/router.js'

export default {
  name: 'NavBar',
  data () {
    return {
      menuItems: [
        { label: 'About', to: '/' },
        { label: 'X-ray', to: '/xray/' },
        { label: 'MRI', to: '/mri/' },
        { label: 'License', to: '/license/' }
        // { label: 'Logout', handler: this.logout }
        //        { label: 'Documentation', to: '/docs' },
        //        { label: 'Billing', to: '/billing' },
      ]
    }
  },
  methods: {
    onClick (mi) {
      if ('handler' in mi) { mi.handler() }
    },
    // logout () {
    //   store.commit('setAPIKey', '')
    //   router.push({ path: '/' })
    // }
  },
  computed: {
    // hasKey () {
    //   return store.state.apiKey.length > 0
    // }
  }
}
</script>

<style>
/* Original HTML/CSS align */
ul.navbar-nav > li a {
  padding: 0px 0px 0px 0px !important;
}

@media only screen and (min-width: 992px) {
  .navbar-nav > li:not(:last-child) a:after {
    content: "|";
    color: #ccc;
    display: inline-block;
    font-size: 100%;
    padding: 0 .5em;
  }
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #005A77;
  /*color: #1a3f71;*/ /* other optional colour */
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0rem;
  padding-left: 0rem;
}
</style>
