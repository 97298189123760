import Vue from 'vue'

// Bootstrap
import BootstrapVue from "bootstrap-vue"
import vueHeadful from 'vue-headful';
Vue.use(BootstrapVue);
Vue.component('vue-headful', vueHeadful);

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faMap, faPhone, faEnvelope, faListAlt, faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import round from 'vue-round-filter'

library.add(faInfoCircle, faMap, faPhone, faEnvelope, faLinkedin, faListAlt, faCheckCircle, faTimesCircle, faSpinner)
Vue.component('font-awesome-icon', FontAwesomeIcon)
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/styles.scss'

Vue.config.productionTip = false
Vue.filter('round', round)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
