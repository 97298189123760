<template>
    <div id="poc" class="p-t-4 p-b-4 section-bg-dark">
    <b-container>

    <!--Section heading-->
    <div class="text-center">
    <h1 class="font-weight-bold text-center my-4" id="license">License agreement</h1>
    </div>

        <div class="row align-items-center">

    <div class="row col-md-12">

<ol>
<li> The Board of Trustees of the Leland Stanford Junior University ("Stanford") provides KneeNet & T2 Mapper software and code ("Service") free of charge for non-commercial use only. Use of the Service by any commercial entity for any purpose, including research, is prohibited.</li>
<li> By using the Service, you agree to be bound by the terms of this Agreement. Please read it carefully.</li>
<li> You agree not to use the Service for commercial advantage, or in the course of for-profit activities. You agree not to use the Service on behalf of any organization that is not a non-profit organization. Commercial entities wishing to use this Service should contact Stanford University's Office of Technology Licensing.</li>
<li> THE SERVICE IS OFFERED "AS IS", AND, TO THE EXTENT PERMITTED BY LAW, STANFORD MAKES NO REPRESENTATIONS AND EXTENDS NO WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. STANFORD SHALL NOT BE LIABLE FOR ANY CLAIMS OR DAMAGES WITH RESPECT TO ANY LOSS OR OTHER CLAIM BY YOU OR ANY THIRD PARTY ON ACCOUNT OF, OR ARISING FROM THE USE OF THE SERVICE. YOU HEREBY AGREE TO DEFEND AND INDEMNIFY STANFORD, ITS TRUSTEES, EMPLOYEES, OFFICERS, STUDENTS, AGENTS, FACULTY, REPRESENTATIVES, AND VOLUNTEERS ("STANFORD INDEMNITEES") FROM ANY LOSS OR CLAIM ASSERTED AGAINST STANFORD INDEMNITEES ARISING FROM YOUR USE OF THE SERVICE.</li>
<li> All rights not expressly granted to you in this Agreement are reserved and retained by Stanford or its licensors or content providers. This Agreement provides no license under any patent.</li>
<li> You agree that this Agreement and any dispute arising under it is governed by the laws of the State of California, United States of America, applicable to agreements negotiated, executed, and performed within California.</li>
<li> Subject to your compliance with the terms and conditions set forth in this Agreement, Stanford grants you a revocable, non-exclusive, non-transferable right to access and make use of the Service.</li>
<li>Any questions regarding our technology direct to kevin.a.thomas@stanford.edu.</li>
</ol>

    </div>
        </div>
    </b-container>
    </div>
</template>

<script>
export default {
  name: 'License',
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>