async function loadImage (source) {
  const image = new Image()

  return await new Promise(resolve => {
    image.onload = () => {
      resolve(image)
    }

    image.src = source
  })
}

function imageToCanvas (image) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d');

  canvas.width = image.width
  canvas.height = image.height
  context.drawImage(image, 0, 0 )

  return canvas
}

function canvasToImage (canvas) {
  return loadImage(canvas.toDataURL('image/png'))
}

async function splitCanvasInTwoImages (canvas) {
  const canvasLeft = document.createElement('canvas')
  const canvasRight = document.createElement('canvas')
  const ctxLeft = canvasLeft.getContext('2d')
  const ctxRight = canvasRight.getContext('2d')
  const half = Math.round(canvas.width / 2)

  canvasLeft.width = half
  canvasRight.width = canvas.width - half
  canvasLeft.height = canvasRight.height = canvas.height

  ctxLeft.drawImage(canvas, 
                    0, 0, half, canvas.height, // source
                    0, 0, half, canvas.height) // dest

  ctxRight.drawImage(canvas, 
                    half + 1, 0,canvas.width - half, canvas.height, // source
                    0, 0, canvas.width - half, canvas.height) // dest

  return [
    await canvasToImage(canvasLeft),
    await canvasToImage(canvasRight)
  ]
}

export {
  loadImage,
  imageToCanvas,
  canvasToImage,
  splitCanvasInTwoImages
}