import Vue from 'vue'
import Router from 'vue-router'
import General from './views/General'
import Demo from './views/Demo'
import DemoMRI from './views/DemoMRI'
import License from './components/License'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'General',
      component: General
    },
    {
      path: '/xray/',
      name: 'Demo',
      component: Demo
    },
    {
      path: '/mri/',
      name: 'DemoMRI',
      component: DemoMRI
    },
    {
      path: '/license/',
      name: 'License',
      component: License
    }
    /*,
    {
      path: '/docs',
      name: 'Documentation',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(
        // webpackChunkName: "documentation"
        './views/Documentation')
    },
    {
      path: '/billing',
      name: 'Billing',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(
        // webpackChunkName: "billing"
        './views/Billing.vue')
    }
    */
  ]
})
