<template>
  <div>
    <vue-headful title="KneeNet Biomarkers"/>
    <TextInfoBlock/>
    <!-- Other blocks -->
    <Creators/>
    <ContactForm/>
  </div>  
</template>

<script>
import tst from '@/components/Toasts/Toasts.js'
import NavBar from '@/components/NavBar'
import Creators from '@/components/Creators'
import ContactForm from '@/components/ContactForm'
import TextInfoBlock from '@/components/TextInfoBlock'
import store from '@/store.js'
import router from '@/router.js'

export default {
  name: 'General',
  components: {
    NavBar,
    Creators,
    ContactForm,
    TextInfoBlock,
  },
  data () {
    return {
    }
  },
  computed: {
    // hasKey () {
    //   return this.$store.state.apiKey.length > 0
    // },
  },
  async created () {
  },
  watch: {
  },
  methods: {
  },
  // created: function(){
  //   if (this.$route.query.key){
  //     router.push({ name: 'Demo' })
  //   }
  // }
}
</script>
