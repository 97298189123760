import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'
Vue.use(Vuex)
import toasts from '@/components/Toasts/ToastStoreModule.js'

export default new Vuex.Store({
  modules: {
    toasts
  },
  state: {
    apiKey: ''
  },
  mutations: {
    // setAPIKey (state, key) {
    //   state.apiKey = key
      // axios
//      axios.defaults.headers.common['Authorization'] = 'Token ' + key
    }
  
})
