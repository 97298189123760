<template>
  <div class="container">
    <h1 class=" h1-responsive font-weight-bold text-center my-4" style="padding: 0.5em;">Research Team</h1>

    <b-row class="m-t-2 justify-content-center">
      <div class="col-md-4 person text-center">
        <img src="@/assets/img/team/Kevin_Thomas.jpg"  ><br />
        <div class="name">Kevin Thomas</div>
        <div class="role">M.D./Ph.D. Candidate @ Stanford &nbsp; <a href="https://www.linkedin.com/in/kevin-a-thomas-04b91a45/"><i class="fab fa-linkedin"></i></a></div>
      </div>

      <div class="col-md-4 person text-center">
        <img src="@/assets/img/team/Lukasz_Kidzinski.jpg" ><br />
        <div class="name">Lukasz Kidzinski, Ph.D.</div>
        <div class="role">Bioengineering Researcher @ Stanford &nbsp; <a href="https://www.linkedin.com/in/lukaszkidzinski/"><i class="fab fa-linkedin"></i></a></div>
      </div>
    </b-row>

    <h1 class=" h1-responsive font-weight-bold text-center my-4" style="padding: 0.5em;">Advisors</h1>      

    <b-row class="mt-4 mb-5 justify-content-center">
      <div class="col-md-4 person text-center">
        <img src="@/assets/img/team/Garry_Gold.jpg" ><br />
        <div class="name">Garry Gold, M.D.</div>
        <div class="role">Professor of Radiology @ Stanford &nbsp; <a href="https://www.linkedin.com/in/garry-gold-9a242a3/"><i class="fab fa-linkedin"></i></a></div>
      </div>
      
      <div class="col-md-4 person text-center">
        <img src="@/assets/img/team/Scott_Delp.jpg" >
        <div class="name">Scott Delp, Ph.D.</div>
        <div class="role">Professor of Bioengineering @ Stanford &nbsp; <a href="https://www.linkedin.com/in/scott-delp-569651ab/"><i class="fab fa-linkedin"></i></a></div>
        <div class="blurb"></div>
      </div>
    </b-row>
  </div>  
</template>

<script>
export default {
  name: 'Creators'
}
</script>

<style>
.person > img {
    text-align: center;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    
}
.person > .name {
    font-size: 1.7em;
}
.person > .role {
    font-size: 1.3em;
    display: block;
    position: relative;
    top: -0.1em;
}
.person > .blurb {
    font-size: 0.9em;
    color: #6c757d;
}
a .fa-linkedin {
    color: #cccccc;
}
a:hover .fa-linkedin {
    color: #0077B5;
}
</style>
